import * as Sentry from "@sentry/remix";

/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { HydratedRouter } from "react-router/dom";

import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { useLocation, useMatches } from "react-router";

Sentry.init({
  enabled: process.env.NODE_ENV !== "development",
  environment: process.env.NODE_ENV,
  dsn: "https://5146390434c20f4c53f0ebf770c73a22@o1173369.ingest.us.sentry.io/4507938797125632",
  tracesSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // eslint-disable-next-line import/namespace
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  ignoreErrors: [
    "Load failed",
    "Failed to fetch",
    "Fetch is aborted",
    "BodyStreamBuffer was aborted",
    "Root did not complete",
    "This is a bug in React",
    "Event `ProgressEvent` (type=error) captured as promise rejection",
    "This Suspense boundary received an update before it finished hydrating",
    "_controlCorners", // leaflet
  ],
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <HydratedRouter />
    </StrictMode>,
  );
});
